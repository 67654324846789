




























































import { Route } from 'vue-router'
// import { ProjectService }          from '@/services/project'
import { projectModule } from '@/store'
import { Component, Prop, Vue }    from 'vue-property-decorator'

// import SearchInput  from '@/components/inputs/Search.vue'
// import SmallLoader  from '@/components/loaders/SmallLoader.vue'
// import Pagination   from '@/components/paginations/Pagination.vue'
// import TableHeading from '@/components/table/Heading.vue'
// import TableRow     from '@/components/table/Row.vue'
// import TableWrapper from '@/components/table/Wrapper.vue'
// import NoItems      from '@/components/partials/NoItems.vue'
// import DefaultModal  from '@/components/modals/Default.vue'

// import { AuthUserResource }           from '@/models/users/AuthUserResource'
// import { ProjectResource }            from '@/models/projects/ProjectResource'
// import { DocumentCollectionResource } from '@/models/documents/DocumentCollectionResource'

// @Component({
//   components: {
//     NoItems,
//     SearchInput,
//     TableRow,
//     Pagination,
//     TableHeading,
//     TableWrapper,
//     SmallLoader,
//     DefaultModal
//   }
// })
@Component
export default class ProjectDocuments extends Vue {

  // @Prop()
  // private readonly project!: ProjectResource

  // Data
  // private loading: boolean = false
  // private index: IndexResponse<DocumentCollectionResource> | null = null
  // private projectService!: ProjectService
  // private params: IndexParameters = {
  //   column: 'title',
  //   dir: 'desc',
  //   search: '',
  //   page: '1'
  // }
  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {

    if (projectModule.detail?.documentProcess) {
      next({ name: 'projects-detail-process', params: { project_id: projectModule.detail.id.toString(), process_id: projectModule.detail?.documentProcess.id } })
    } else {
      next({ name: 'projects-single-detail', params: { project_id: projectModule.detail?.id.toString() } })
    }
  }

  // private deletingDocumentModal: { open: boolean, loading: boolean, document: DocumentCollectionResource | null } = {
  //   open: false,
  //   loading: false,
  //   document: null
  // }

  // private async created(): Promise<void> {
  //   const project_id = this.$route.params.project_id
  //   if (!project_id) {
  //     console.warn('No project id available to get documents')
  //   }
  //   this.projectService = new ProjectService({ project_id: parseInt(project_id, 10) })
  //   this.setParamsFromRoute()
  //   this.getData()
  // }


  // // Computed
  // private get getTitle(): string {
  //   return `All documents (${this?.index?.data?.length ?? 0})`
  // }

  // private get user(): AuthUserResource | undefined {
  //   return userModule.user
  // }

  // private get canDeleteDocument(): boolean {
  //   return this.project.canPerformAction('can_delete_documents')
  // }

  // private get canAddDocument(): boolean {
  //   return this.project.canPerformAction('can_add_documents')
  // }

  // // Methods
  // private setParamsFromRoute(): void {
  //   this.params.dir = typeof this.$route.query.dir === 'string' ? this.$route.query.dir : 'desc'
  //   this.params.page = typeof this.$route.query.page === 'string' ? this.$route.query.page : '1'
  //   this.params.search = typeof this.$route.query.search === 'string' ? this.$route.query.search : ''
  //   this.params.column = typeof this.$route.query.column === 'string' ? this.$route.query.column : 'title'
  // }

  // private async getData(): Promise<void> {
  //   this.loading = true
  //   try {
  //     this.index = await this.projectService.getDocuments(this.params)
  //     this.$router.replace({ query: this.params })
  //   } finally {
  //     this.loading = false
  //   }
  // }

  // private getRowElements(document: DocumentCollectionResource): TableDataElement[] {
  //   const data: TableDataElement[] = []
  //   if (this.index && this.index.elements) {
  //     this.index.elements.forEach((element: TableElement) => {
  //       const KEY = element.key as keyof DocumentCollectionResource
  //       const VAL = document[KEY]
  //       if (typeof VAL === 'string' || typeof VAL === 'number') {
  //         data.push({
  //           value: VAL,
  //           type: element.type
  //         })
  //       } else {
  //         data.push({
  //           value: '-',
  //           type: element.type
  //         })
  //       }
  //     })
  //   }
  //   return data
  // }

  // private setOrder(col: string, dir: string): void {
  //   const params = { ...this.params }
  //   params.column = col
  //   params.dir = dir
  //   params.page = '1'
  //   this.params = params

  //   this.getData()
  // }

  // private goToPage(page: string): void {
  //   this.params.page = page
  //   this.getData()
  // }


  // private async deleteDocument(): Promise<void> {
  //   const project_id = parseInt(this.$route.params.project_id, 10)
  //   if (this.deletingDocumentModal.document) {
  //     this.deletingDocumentModal.loading = true
  //     try {
  //       await this.projectService.deleteDocument(this.deletingDocumentModal.document.id)
  //       await this.getData()
  //       await projectModule.getById(project_id)
  //       this.closeDeletingModal()
  //     } finally {
  //       this.deletingDocumentModal.loading = false
  //     }
  //   }
  // }

  // private openDeletingModal(id: number): void {
  //   const document = this.index?.data.find((d) => d.id === id)
  //   if (document) {
  //     this.deletingDocumentModal.document = document
  //     this.$nextTick(() => {
  //       this.deletingDocumentModal.open = true
  //     })
  //   }
  // }

  // private closeDeletingModal(): void {
  //   this.deletingDocumentModal.open = false
  //   this.deletingDocumentModal.loading = false
  //   this.deletingDocumentModal.document = null
  // }
}
